


























































































import ErrorsBadge from "@/components/ErrorsBadge.vue";
import DynamicResolver from "@/components/explore/DynamicResolver.vue";
import EditItemButton from "@/components/explore/EditItemButton.vue";
import IndentedEntry from "@/components/explore/IndentedEntry.vue";
import { useElementErrors } from "@/components/explore/renderer/setup/element-errors";
import {
  determineRenderingOptions,
  RenderingOptions,
} from "@/components/explore/support/summary";
import {
  ArrayWrapper,
  ChoiceWrapper,
  ElementWrapper,
  getSliceName,
} from "@/lib-on-fhir";
import { config } from "@/services/config";
import { defineComponent } from "vue-demi";

export default defineComponent({
  props: {
    element: {
      type: ChoiceWrapper,
      required: true,
    },
    fieldId: {
      type: String,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
    errorStackSize: {
      type: Number,
      required: true,
    },
  },
  components: {
    DynamicResolver,
    IndentedEntry,
    EditItemButton,
    ErrorsBadge,
  },
  computed: {
    renderingOptions(): RenderingOptions | undefined {
      let value = this.element.value;
      if (value instanceof ArrayWrapper && value.items.length === 1) {
        value = value.items[0];
      }
      if (!(value instanceof ElementWrapper)) {
        return;
      }
      return determineRenderingOptions(value, this.depth);
    },

    allowedTypes(): any[] {
      return this.element.$type.allowedTypes.map((type, index) => {
        let sliceName = getSliceName(type);
        return {
          text: type.typeName + (sliceName ? ` (${sliceName})` : ""),
          value: index,
        };
      });
    },

    sliceName(): string | undefined {
      if (!this.element.value) {
        return;
      }
      return getSliceName(this.element.value.$type);
    },

    childDepth(): number {
      return this.depth < 1 ? this.depth + 2 : this.depth + 1;
    },

    isMultiValue(): boolean {
      return this.element.$type.allowedTypes.length > 1;
    },

    readonly(): boolean {
      return config.appConfig.readOnlyMode;
    },
  },
  watch: {
    isMultiValue(value: boolean) {
      if (!value) {
        this.expanded = false;
      }
    },
  },
  data: () => ({
    expanded: false,
  }),

  setup(props) {
    return {
      ...useElementErrors(props, {}),
    };
  },

  methods: {
    changeTypeIndex(index: number) {
      const type = this.element.$type.allowedTypes[index];
      this.element.changeType(type);
    },
    deleteValue() {
      this.element.deleteValue();
    },
  },
});
